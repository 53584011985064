<template>
  <div class="formContainer">
    <div class="main">
      <el-tree :data="regionList" :props="treeSelectProps" show-checkbox node-key="code" accordion ref="tree" default-expand-all @check-change="checkChange" check-on-click-node />
    </div>
    <div class="formBox">
      <el-form ref="dform" label-position="right" label-width="170px" :model="form">
        <el-form-item label="指令类型" prop="cmdType" :rules="[{ required: true, message: '指令类型 不能为空' }]">
          <el-select v-model="form.cmdType" placeholder="请选择 指令类型">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option></el-select>
        </el-form-item>

        <el-form-item label="是否更改默认频点" prop="change" :rules="[{ required: true, message: '是否更改默认频点 不能为空' }]">
          <el-select v-model="form.change" placeholder="请选择 是否更改默认频点">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option></el-select>
        </el-form-item>

        <el-form-item label="频点信息值" prop="frequency" :rules="[{ required: true, message: '频点信息值 不能为空' }]">
          <el-input-number :min="0.00" :max="9999.99" :precision="2" clearable autocomplete="off" v-model="form.frequency" placeholder="请输入 频点信息值(秒)"></el-input-number>
        </el-form-item>

        <el-form-item label="维持时间(秒)" prop="sendTimes" :rules="[{ required: true, message: '维持时间(秒) 不能为空' }]">
          <el-input-number :min="1" :max="255" clearable autocomplete="off" v-model="form.sendTimes" placeholder="请输入 维持时间(秒)"></el-input-number>
        </el-form-item>

        <el-form-item label="发送地址信息" prop="regionCodes" :rules="[{ required: true, message: '发送地址信息 不能为空' }]">
          <el-input readonly clearable type="textarea" autocomplete="off" v-model="form.regionCodes" placeholder="请选择 发送地址信息"></el-input>
        </el-form-item>
        <el-button  plain @click="addForm('dform')" class="searchBtn">提交</el-button>
      </el-form>
    </div>
  </div>
</template>
<script>
import { resetDevice } from "@/api/terminalRegulate/generalVolume";
import { getRegionEquip } from "@/api/system/region";
import {nextTick, onMounted, reactive, ref, toRefs} from "vue";
import {ElMessage} from "element-plus";

export default {
  setup(){
    let dform = ref(null);
    let tree = ref(null);
    let state = reactive({
      codes:[],
      options:[
        {
          value: '1',
          label: '更改'
        },
        {
          value: '2',
          label: '不更改'
        }
      ],
      options2:[
        {
          value: '0',
          label: '不变'
        },
        {
          value: '1',
          label: '复位'
        }
      ],
      form:{
        sendTimes:"",
        frequency:"",
      },
      regionList: [],
      codeList: [],
      treeSelectProps: {
        label: "name",
        value: "id",
        key: "code",
        children: "children"
      }
    })

    onMounted(()=>{
      getRegionEquip().then(res => {
        if (res.data.success) state.regionList = res.data.data.list;
      });
    })

    function getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function(node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    }

    function checkChange(node, status) {
      state.codeList = getSimpleCheckedNodes(tree.value.store);
      state.form.regionCodes = state.codeList.map(item=>item.name).join(",");
      state.codes = state.codeList.map(item=>item.code).join(",");
    }

    function addForm(){
      dform.value.validate(async (valid) => {
        if (valid) {
          let { cmdType, change, frequency, sendTimes } = state.form;
          let obj = new FormData();
          obj.append("cmdType", cmdType);
          obj.append("change", change);
          obj.append("frequency", frequency.toFixed(2));
          obj.append("sendTimes", sendTimes);
          obj.append("regionCodes", state.codes);
          let res = await resetDevice(obj)
          if (res.data.success) {
            ElMessage({type: "success", message: "操作成功！"});
            nextTick(()=>{
              dform.value.resetFields();
            })
          }
        }else {
          return false
        }
      })
    }


    return{
      addForm,
      dform,
      tree,
      checkChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>
.formContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.main {
  width: 300px;
  ::-webkit-scrollbar {
    width: 0;
  }
}
.el-tree {
  height: 700px !important;
  overflow-y: scroll;
}

.formBox {
  width: 1000px;
}

.el-tree-node__content {
  margin-top: 20px !important;
}
</style>